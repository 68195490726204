import React from 'react'
import styled from 'styled-components';
import parse from "html-react-parser"
import {GatsbyImage} from 'gatsby-plugin-image'
import { Link } from "gatsby"

const ListingComp = styled.article`
width:calc(100% - 2rem);
background:var(--grey);
padding:1rem;
margin:0 0 1rem 1rem;
min-height:150px;
position: relative;
overflow:hidden;
transition:0.5s all ease-in-out;
border-radius:5px;


    &.is_active,
    &.active,
    &:hover{
    background:var(--black);

        a{
        color:var(--white);
        }

        .image .gatsby-image-wrapper img{
        transform:scale(1.2);
        }

    }

    a{
    text-decoration:none;
    color:var(--black);
    width:100%;
    transition:0.5s all ease-in-out;
    }

    .image{
    position: absolute;
    top:0;
    right:0;
    width:150px;
    height:100%;

        .gatsby-image-wrapper{
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        left:0;
        
            img{
            transition:0.5s all ease-in-out !important;
            }

        }

    }

    .info{
    position: relative;
    width:calc(100% - 150px);
    display:flex;
    flex-wrap:wrap;
    flex-direction:column;
    justify-content:space-between;
    height:100%;
    
        .heading{
         z-index:0;   
        }
        .heading,
        .tagline,
        .offer,
        .price{
        margin-bottom:1rem;
        width:100%;
        line-height:1.5rem;
        }

        .offer-available{
        margin-bottom:1rem;

            @media only screen and (min-width: 1024px) {
            display:none;
            }
        }
        

        
        .price{
        font-family:var(--font-bold);
        margin-bottom:0;
        }

        .tagline,
        .offer{
        display:none;

            @media only screen and (min-width: 1024px) {
            display:block;
            }

        }
        
        .address{
        font-size:0.75rem;
        }



    }

`


export default function Listing(data) {
    const dataListing = data.listing
    const customClass = data.customClass
    return (
  
        <ListingComp className={customClass}>
            <Link to={'/properties/'+dataListing.slug}>
                <div className="info">
                    <h2 className="heading">{dataListing.title}</h2>
                    {dataListing.terms ? <div className="tagline">{dataListing.terms}</div> : ''}
                    {dataListing.size ? <div className="tagline">{dataListing.size}sqm</div> : ''}
                    <div className="price">£{dataListing.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} p/a</div>
                </div>
                <div className="image">
                    <GatsbyImage image={dataListing.img}/>  
                </div>
            </Link>
        </ListingComp>
     
    )
}
