import React from "react"
import { graphql } from "gatsby"
import styled from 'styled-components';
import MapFilters from '../components/properties/MapFilters'
import Seo from "../components/seo"

const PropertiesMapWrapper = styled.div`

  .independents-map{
  background:var(--teal);
  }

`

const PropertiesIndex = (data) => {
  const allProperties = data.data.properties;
  const terms = data.data.filterTerms;
  const size = data.data.filterSize;
  const price = data.data.filterPrice;
  const map = data.data.map;
  const seo = data.data.seo.seo;
  
  return (
    
    <PropertiesMapWrapper>
      <Seo title={seo.title} description={seo.metaDesc} />
      <MapFilters 
        // offersState={offersState !== null ? offersState : false}
        // basicListings={basicListings}
        // premiumListings={premiumListings}
        allProperties={allProperties}
        // offerListings={offerListings}
        terms={terms}
        size={size}
        price={price}
        map={map}
      />
    </PropertiesMapWrapper>
  )
}

export default PropertiesIndex

export const pageQuery = graphql`
  query PropertiesArchive {
    seo:  wpPage(title: {eq: "Properties Finder"}){
      seo {
        metaDesc
        title
        metaRobotsNofollow
        metaRobotsNoindex
        metaKeywords
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          localFile {
            id
          }
        }
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        twitterTitle
        twitterDescription
      }
    }
    #================================
    # Map
    #================================
    map: wp {
      options {
        acf_options {
          mapCentreLatitute
          mapCentreLongitude
        }
      }
    }

    properties: allWpRetailProperty {
      nodes {
        title
        slug
        acf_retail_properties {
          terms
          size
          price
          mainDescription
          retailLongitude
          retailLatitude
          agentUrl
          images {
            image{
              localFile{
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }

    

    #================================
    # TERMS FILTERS
    #================================

    filterTerms: allWpPropertyTerms {
      nodes {
        slug
        name
      }
    }

    #================================
    # SIZE FILTERS
    #================================
    filterSize: allWpPropertySize {
      nodes {
        slug
        name
        description
      }
    }
    
    #================================
    # SIZE PRICE
    #================================
    filterPrice: allWpPropertyPrice {
      nodes {
        slug
        name
        description
      }
    }

    



  }
`