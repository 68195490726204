import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react';
import MarkerClusterer from '@googlemaps/markerclustererplus';
// import MapMarkerCluster from '../../images/map-marker-cluster.svg'
import InfoWindowTriangle from '../../images/info-window-triangle.svg'
import MapMarkerProperty from '../../images/map-marker-property.svg'
import MapMarkerPropertyHover from '../../images/map-marker-property-hover.svg'
import {IoIosContacts} from 'react-icons/io';
import {GrSort} from 'react-icons/gr';
import DD from '../../assets/dd.inline.svg'
import styled from 'styled-components';
import Button from '../global/Button';
import {GatsbyImage} from 'gatsby-plugin-image'
import mapStyles from '../../styles/map-styles.json'
import Listing from './Listing';

// import MapListingDetail from './MapListingDetail';
import {IoIosCloseCircleOutline} from 'react-icons/io'
import { connectInfiniteHits } from 'react-instantsearch-core';

const IndependentsMapAndFilter = styled.div`
width:100vw;
height:calc(100vh - var(--headerHeight));
height: calc((var(--vh, 1vh) * 100) - var(--headerHeight) );
display:block;
background:var(--teal);
position: relative;
overflow:hidden;

    @media only screen and (min-width: 1024px) {
    display:flex;
    }

button.gm-control-active{
background:var(--teal) !important;
transition:0.5s all ease-in-out !important;

    &:hover{
    filter:contrast(2.5) !important;
    }

    > img{
    filter: brightness(3.5) !important;
    }

}

`
const User = styled.div`
transform:translate(-50%, -50%);
background-color: rgba(218, 54, 73, 1);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: shine 2s ease-in-out infinite;
  position: fixed;

  @keyframes shine {
  0%, 20% {
    box-shadow: 0px 0px 0px 0px rgba(218, 54, 73, 1); 
  }
  100% {
    box-shadow: 0px 0px 0px 50px rgba(0, 0, 0, 0); 
  }
}
`

const ToggleWrap = styled.div`
position:absolute;
bottom:0;
left:0;
width:100%;
height:43px;
display:flex;
background:var(--teal);
z-index:20;
transition:0.5s all ease-in-out;

 

    @media only screen and (min-width: 1024px) {
    display:none;
    }

    .button{
    text-align:center;
    width:50%;
    cursor: pointer;
    transition:0.5s all ease-in-out;
    display:flex;
    align-items:center;
    justify-content:center;
    border-top:1px solid var(--black);

        &.active,
        &:hover{
        background:var(--black);
        color:var(--teal);
            &:first-child{
                svg path{
                stroke:var(--teal);
                }
            }
            &:nth-child(2){
                svg path{
                fill:var(--teal);
                }
            }

        }

        svg{
        margin-right:0.5rem;
        margin-top:-4px;
        }

        svg path{
            transition:0.5s all ease-in-out;
        }

        span{
        margin-right:4px;
        }

        &:first-child{
        border-right:1px solid var(--black);
        }
    
    }

    &.error{
    background:var(--red);

        .button{
            &.active,
            &:hover{
            background:var(--black);
            color:var(--red);
                &:first-child{
                    svg path{
                    stroke:var(--red);
                    }
                }
                &:nth-child(2){
                    svg path{
                    fill:var(--red);
                    }
                }

            }  
        }

    }

`

const MapWrap = styled.div`
position:relative;
background:var(--black);
width:100vw;
/* height:calc(100% - var(--headerHeight)); */
height:calc(100vh - var(--headerHeight) - 43px);
min-height: calc((var(--vh, 1vh) * 100) - var(--headerHeight) - 43px );

    @media only screen and (min-width: 1024px) {
        height:100%;
        width:60vw;
    }
    
    *:focus,
    *::focus{
    outline:none !important;
    }


 /* ----------------------------------------------
 * Generated by Animista on 2021-4-8 16:46:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-top
 * ----------------------------------------
 */
 @-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}


    .map-loading{
    width:100%;
    height:100%;
    font-family:var(--font-heading);
    font-size:4rem;
    letter-spacing:2px;
    text-transform:uppercase;
    transition:0.5s all ease-in-out;
    color:var(--teal);
    display:flex;
    justify-content:center;
    align-items:center;
    
        /* .beacon{
        margin-left: 20px;
        margin-top: 20px;
        } */

        span{
            /* margin-top:100px; */
            justify-content:center;
            align-items:center;
            -webkit-animation: bounce-top 0.9s both;
	        animation: bounce-top 0.9s both;

        }
    }

  


    .map-wrap-inner{
    opacity:0;
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    transition:0.5s all ease-in-out;
    }

    &.loaded{
        .map-wrap-inner{
        opacity:1;
        }
        .map-loading{
        opacity:0;
        }
    }

`

const ErrorWarning = styled.div`
padding:0 1rem;
line-height:1.5rem;

    &.error{
    color:var(--white);
    }

`


const FilterWrap = styled.div`
transition:0.5s all ease-in-out;
/* padding:2rem 2rem 2rem 4rem; */
width:100vw;
position: absolute;
bottom: 0;
height: 222px;
transform:translateY(100%);

    &.showFilters{
    transform:translateY(-43px);    
    }

    @media only screen and (min-width: 1024px) {
        transform:translateY(0);
        position:relative;
        width:40vw;
        right:0;
        /* top:7rem; */
        overflow:scroll;
        height:calc(100vh - var(--headerHeight));
        height: calc((var(--vh, 1vh) * 100) - var(--headerHeight) );
    }

  &:before{
    content:"";
    width:40vw;
    height:100%;
    background:var(--teal);
    top:0;
    right:0;
    z-index:0;
  }

  .filters--wrapper{
    position: absolute;
    z-index:1;
    overflow:hidden;
    padding:2rem 1rem 1rem 1rem;
    /* background:var(--teal); */
    transition:0.5s all ease-in-out;
    width:100%;

    @media only screen and (min-width: 1024px) {
    width:40vw;
    }

    &.error{
    background:var(--red);
    color:var(--white);

        select,
        .clear,
        .offers span{
            color:var(--white);
        }

        .dd polygon{
            fill:var(--white);
        }

        select,
        .offers{
        border-bottom:1px solid var(--white);
        }

        select,
        .offers{
            &:hover{
            background:var(--white);
            color:var(--red);
                span{
                    color:var(--red);
                }
            }
        }

        .offers.checked{
        background:var(--white);
            span{
            color:var(--red);
            }
        }

    }

  }


  .heading{
    font-family:var(--font-bold);
    font-size:1.4rem;
    margin-bottom:1rem;
    z-index:1;
    position: relative;
  }

  .clear{
    position: absolute;
    top:2rem;
    right:1rem;
    background:none;
    border:none;
    font-size:0.8rem;
    display:flex;
    align-items:center;
    cursor: pointer;
    z-index:10;
    padding:0.25rem;
    border-radius:5px;
    transition:0.5s all ease-in-out;
    opacity:1;
    transform:translateX(0%);
    color:var(--white);

        &.hidden{
        opacity:0;
        transform:translateX(100%);
        }

        &:focus{
        outline:none;
        }

        &:hover{
        background:var(--white);
        color:var(--teal);
        }

        svg{
        position: relative;
        width:20px;
        height:20px;
        margin-left:3px;
        top:-1px;
        

            /* path:nth-child(1){
                fill:pink;
            }
            path:nth-child(2){
                fill:pink;
            } */

        }

  }

  .filters-inner-wrapper{
    width:calc(100% + 1rem);
  }
  
  .select-wrap{
    position: relative;
    display:inline-block;
    width:calc(100% - 1rem);
    margin-bottom:1rem;
    
    @media only screen and (min-width: 1024px) {
        width:calc(33.33% - 1rem);
        margin-right:1rem;
        margin-bottom:0;
    }

    .dd{
    position: absolute;
    width:10px;
    height:100%;
    right:0.5rem;
    pointer-events:none;

        polygon{
        fill:var(--white);
        transition:0.5s all ease-in-out;
        }

    }

    &:hover{
        .dd{
            polygon{
            fill:var(--teal);
            }
        }
    }
  }

  select{
    width:100%;
    background:none;
    border:0;
    font-family:var(--font-bold);
    padding:0.5rem;
    border-bottom:1px solid var(--white);
    cursor: pointer;
    transition:0.5s all ease-in-out;
    border-radius:5px 5px 0 0;
    color:var(--white);
   
        &:hover{
        background:var(--white);
        color:var(--teal);
        }

  }

  .offers{
    position: relative;
    font-family:var(--font-bold);
    border:none;
    border-bottom:1px solid var(--white);
    display:inline-block;
    cursor: pointer;
    padding:0.5rem;
    background:none;
    text-align:left;
    transition:0.5s all ease-in-out;
    border-radius:5px 5px 0 0;
    width:calc(33.33% - 1rem);
    width:calc(100% - 1rem);
    color:var(--white);
    
    @media only screen and (min-width: 1024px) {
        width:calc(33.33% - 1rem);
        margin-right:1rem;
    }

    &:focus{
        outline:none;
    }

    &:hover{
    background:var(--white);
    color:var(--teal);
    }

    &.checked{
    background:var(--white);
    color:var(--teal);
    }

    span{
        pointer-events:none;
    }

    input[type="checkbox"]{
    width:100%;
    position: absolute;
    left:0;
    /* visibility:hidden; */
    }
  }

  select{
      &:focus{
        outline:none;
      }
  }

`

const CurrentlyViewing = styled.div`
position:absolute;
bottom:0;
left:0;
width:100%;
`


const ListingsContainer = styled.div`

.listing{

    &:last-child{
    
    /* margin-bottom:12rem; */
    margin-bottom:8rem;

        @media only screen and (min-width: 1024px) {
        margin-bottom:1rem;
        }

    }
}

`
const ListingWrapper = styled.div.attrs(props => ({
    className:props.className
}))`

    .infowindow{
    position:fixed;
    left:0;
    top:0;
    z-index:-10;
    /* top:-100vh;
    left:-100vw;
    z-index:-10; */
    
        @media only screen and (min-width: 1024px) {
        /* top:0;
        left:0; */
        /* z-index:-10; */
        }

    }

    
`


const ListingsWrap = styled.div`
    z-index:0;
    overflow:scroll;
    position: fixed;
    top:0;
    right:0;
    top: 0;
    right: 0;
    height: 100vh;
    background: var(--teal);
    width: 100vw;
    scroll-behavior: smooth;
    padding: 1rem 0;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    transform:translateY(100%);
    transition:0.5s all ease-in-out;

    @media only screen and (min-width: 1024px) {
        transform:translateY(0);
    }

    &.error{
    background:var(--red);
    color:var(--white);

        &:before{
        opacity:0;
        }

    }

    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }

    &.showListings{
        transform:translateY(0);
    }

    &:before{
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    z-index:0;
    background:var(--teal);
    content:'';
    opacity:1;
    transition:0.5s all ease-in-out;
    }
    
    
    @media only screen and (min-width: 1024px) {
        margin:121px 0 0 0 ;
        width:40vw;
        padding: 0;
        background:none;
        /* display:block; */
        overflow:scroll;
        height:calc(100vh - 241px);
        height: calc((var(--vh, 1vh) * 100 - 241px));
        max-height:calc(100vh - 241px);
        max-height: calc((var(--vh, 1vh) * 100 - 241px));
    }

  .heading{
    font-family:var(--font-bold);
    font-size:1.4rem;
    margin-bottom:1rem;
    z-index:1;
    position: relative;
  }
`


const InfoWindow = styled.div`
width:260px;
background:var(--grey);
text-align:center;
padding:0;
position:relative;
transition:0.5s all ease-in-out;

    &:after{
    width: 20px;
    content: "";
    height: 15px;
    background-image: url(${InfoWindowTriangle});
    background-size:100% 100%;
    position: absolute;
    bottom: calc(0% - 30px);
    left: calc(50% - 10px);
    }


    .image{
    width:100%;
    height:100px;
    overflow:hidden;

        .gatsby-image-wrapper{
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        left:0;
        
            img{
            transition:0.5s all ease-in-out !important;
            }

        }
    }


    .info{
    padding:1rem;
    text-align:left;
    font-family:var(--font-reg);


        .heading{
         z-index:0;  
         font-family:var(--font-bold);
         font-size:1.4rem;
        }
        .heading,
        .tagline,
        .offer{
        margin-bottom:0.5rem;
        width:100%;
        line-height:1.25rem;
        }

        .tagline,
        .offer{
        font-size:0.9rem;
        }

        .price{
        font-family:var(--font-bold);
        }
        
        .address{
        font-size:0.75rem;
        }
    }
    
    .btn-wrap{
    width:calc(100% - 2rem);
    margin-left:1rem;
    margin-bottom:1rem;

        div,
        a{
        width:100%;
        }

    }

`

export default class MapFilters extends Component {

    constructor(props) {
        super(props);
            
        this.state = {
            lat: parseFloat(this.props.map.options.acf_options.mapCentreLatitute),
            lng: parseFloat(this.props.map.options.acf_options.mapCentreLongitude),
            userLat:null,
            userLng:null,
            userConsent: false,
            userPositionSet: false,
            hasPanned:false,
            zoom: 16,
            terms:'*',
            price:'*',
            size:'*',
            listingsRadius: 200,
            allInboundListings: null,
            markers:[],
            maps:null,
            map:null,
            mapLoaded:false,
            InfoWindowTitle:"test",
            InfoWindowImg: null,
            InfoWindowTagline: 'test',
            InfoWindowOffer: 'test',
            InfoWindowAddress: 'test',
            InfoWindowSlug: 'test',
            mapBounds:null,
            filterActive:false,
            listingsActive:false,
            // showResetButton: displayOffers === 'true' ? true : false, 
            listingsWithActiveInfowindow:null,
            results:[],
            allResults:this.props.allProperties.nodes,
        };
        this.filter1 = React.createRef();
        this.filter2 = React.createRef();
        this.filter3 = React.createRef();
        this.filterWrap = React.createRef();
        this.listings = React.createRef();
        this.mapRef = React.createRef();
    }

    componentDidMount(){

        
        
        this.setState({
            items: this.props.allProperties,
        })
            
        
        this.getGeolocationPostion();
       

    }

    toggleFilters = (e) => {
        if(this.state.listingsActive === true){
            this.setState({
                filterActive:!this.state.filterActive,
                listingsActive:false
            })
        }else{
            this.setState({
                filterActive:!this.state.filterActive,
            })
        }
    }

    toggleListings = (e) => {
        if(this.state.filterActive === true){
            this.setState({
                listingsActive:!this.state.listingsActive,
                filterActive:false
            })
        }else{
            this.setState({
                listingsActive:!this.state.listingsActive,
            })
        }
    }

    handleListingHoverHighlight = (e) => {
        this.state.markers.map((marker) => {
            return(
                marker.title === e.currentTarget.querySelector('.heading').innerText ?
                    marker.marker.setIcon(MapMarkerPropertyHover)
                : ""
            )
        })
    } 
    handleListingHoverHighlightClear = (e) => {
        // this.setState({
        //     hoverListing:null
        // })

        this.state.markers.map((marker) => {
            return(
                marker.marker.setIcon(MapMarkerProperty)
            )
        })
    } 

   
    componentDidUpdate(){
        this.noResults();
    }

    handleSelectChange = (e) => {

        const target = e.target.id;
        let items = [];

        if(target === 'filter-terms'){
            this.setState({
                terms: e.target.value
            },() => {
                
               

                this.state.allResults.forEach((item) => {
                    if(
                        this.state.price === '*' 
                        && this.state.size === '*' 
                        && item.acf_retail_properties.terms === this.state.terms 
                    ){
                        return items.push(item);
                    }
                    
                    if(
                        this.state.price !== '*' 
                        && this.state.size === '*' 
                        && Math.floor(item.acf_retail_properties.price) < this.state.price.max 
                        && Math.floor(item.acf_retail_properties.price) > this.state.price.min 
                        && item.acf_retail_properties.terms === this.state.terms 
                    ){
                        return items.push(item);
                    }
                    if(
                        this.state.price === '*' 
                        && this.state.size !== '*' 
                        && Math.floor(item.acf_retail_properties.size) < this.state.size.max 
                        && Math.floor(item.acf_retail_properties.size) > this.state.size.min 
                        && item.acf_retail_properties.terms === this.state.terms 
                    ){
                        return items.push(item);
                    }
                    if(
                        this.state.price !== '*' 
                        && this.state.size !== '*' 
                        && Math.floor(item.acf_retail_properties.price) < this.state.price.max 
                        && Math.floor(item.acf_retail_properties.price) > this.state.price.min 
                        && Math.floor(item.acf_retail_properties.size) < this.state.size.max 
                        && Math.floor(item.acf_retail_properties.size) > this.state.size.min 
                        && item.acf_retail_properties.terms === this.state.terms 
                    ){
                        return items.push(item);
                    }
                });

                this.setState({
                    results:items 
                },() => {
                    this.getInBoundListings()
                })
            })
        }
        if(target === 'filter-size'){
            const minValue = e.target.options[e.target.selectedIndex].getAttribute('minValue');
            const maxValue = e.target.options[e.target.selectedIndex].getAttribute('maxValue');
            this.setState({
                size: {min:parseInt(minValue), max:parseInt(maxValue)}
            },() => {

                
                this.state.allResults.forEach((item) => {
                    if(
                        this.state.price === '*' 
                        && this.state.terms === '*'
                        && Math.floor(item.acf_retail_properties.size) < this.state.size.max 
                        && Math.floor(item.acf_retail_properties.size) > this.state.size.min 
                    ){
                        return items.push(item);
                    }
                    
                    if(
                        this.state.price !== '*' 
                        && this.state.terms === '*'
                        && Math.floor(item.acf_retail_properties.size) < this.state.size.max 
                        && Math.floor(item.acf_retail_properties.size) > this.state.size.min 
                        && Math.floor(item.acf_retail_properties.price) < this.state.price.max 
                        && Math.floor(item.acf_retail_properties.price) > this.state.price.min 
                    ){
                        return items.push(item);
                    }
                    if(
                        this.state.price === '*' 
                        && this.state.terms !== '*'
                        && item.acf_retail_properties.terms === this.state.terms 
                        && Math.floor(item.acf_retail_properties.size) < this.state.size.max 
                        && Math.floor(item.acf_retail_properties.size) > this.state.size.min 
                        
                    ){
                        return items.push(item);
                    }
                    if(
                        this.state.price !== '*' 
                        && this.state.terms !== '*' 
                        && Math.floor(item.acf_retail_properties.price) < this.state.price.max 
                        && Math.floor(item.acf_retail_properties.price) > this.state.price.min 
                        && Math.floor(item.acf_retail_properties.size) < this.state.size.max 
                        && Math.floor(item.acf_retail_properties.size) > this.state.size.min 
                        && item.acf_retail_properties.terms === this.state.terms 
                    ){
                        return items.push(item);
                    }
                });

                this.setState({
                    results:items 
                },() => {
                    this.getInBoundListings()
                })
            })
        }
        if(target === 'filter-price'){
            const minValue = e.target.options[e.target.selectedIndex].getAttribute('minValue');
            const maxValue = e.target.options[e.target.selectedIndex].getAttribute('maxValue');
            this.setState({
                price: {min:parseInt(minValue), max:parseInt(maxValue)}
            },() => {

                this.state.allResults.forEach((item) => {
                    if(
                        this.state.size === '*' 
                        && this.state.terms === '*'
                        && Math.floor(item.acf_retail_properties.price) < this.state.price.max 
                        && Math.floor(item.acf_retail_properties.price) > this.state.price.min 
                    ){
                        return items.push(item);
                    }
                    
                    if(
                        this.state.size !== '*' 
                        && this.state.terms === '*'
                        && Math.floor(item.acf_retail_properties.price) < this.state.price.max 
                        && Math.floor(item.acf_retail_properties.price) > this.state.price.min 
                        && Math.floor(item.acf_retail_properties.size) < this.state.size.max 
                        && Math.floor(item.acf_retail_properties.size) > this.state.size.min 
                    ){
                        return items.push(item);
                    }
                    if(
                        this.state.size === '*' 
                        && this.state.terms !== '*'
                        && item.acf_retail_properties.terms === this.state.terms 
                        && Math.floor(item.acf_retail_properties.price) < this.state.price.max 
                        && Math.floor(item.acf_retail_properties.price) > this.state.price.min 
                        
                    ){
                        return items.push(item);
                    }
                    if(
                        this.state.size !== '*' 
                        && this.state.terms !== '*' 
                        && Math.floor(item.acf_retail_properties.size) < this.state.size.max 
                        && Math.floor(item.acf_retail_properties.size) > this.state.size.min 
                        && Math.floor(item.acf_retail_properties.price) < this.state.price.max 
                        && Math.floor(item.acf_retail_properties.price) > this.state.price.min 
                        && item.acf_retail_properties.terms === this.state.terms 
                    ){
                        return items.push(item);
                    }
                });

                this.setState({
                    results:items 
                },() => {
                    this.getInBoundListings()
                })
            })
        }


        
        



        this.state.markers.forEach((marker) => {
            marker.marker.setVisible(false);
            if(marker.marker.category.terms !== undefined){
                if(marker.marker.category.what.includes(target)){
                    marker.marker.setVisible(true)
                }
            }
        })
        

        this.showResetButton();     
    }


    noResults = () => {
        if(this.state.allInboundListings !== null && this.state.allInboundListings.length === 0){
            this.filterWrap.current.style.backgroundColor = 'var(--red)';
            this.filterWrap.current.style.color = 'var(--white)';
            this.filterWrap.current.querySelector('.filters--wrapper').classList.add('error');

            const isClient = typeof document !== 'undefined';
        
            if(isClient){
                const errorWarning = document.querySelector('.errorwarning')
                const toggleWrap = document.querySelector('.toggleWrap')
                const listingsWrap = document.querySelector('#listings-scroller');
                toggleWrap.classList.add('error');
                listingsWrap.classList.add('error');
                
                if(errorWarning !== null && errorWarning !== undefined){
                    errorWarning.classList.add('error');
                }
            }
        }else{
            this.filterWrap.current.style.backgroundColor = 'var(--teal)';
            this.filterWrap.current.style.color = 'var(--white)';
            this.filterWrap.current.querySelector('.filters--wrapper').classList.remove('error');

            const isClient = typeof document !== 'undefined';
        
            if(isClient){
                const errorWarning = document.querySelector('.errorwarning')
                const toggleWrap = document.querySelector('.toggleWrap')
                const listingsWrap = document.querySelector('#listings-scroller');
                toggleWrap.classList.remove('error');
                listingsWrap.classList.remove('error');
                
                if(errorWarning !== null && errorWarning !== undefined){
                    errorWarning.classList.remove('error');
                }
            }
        }
    }
    

    resetFilters = () => {
       
        // Get user position
        let newLatLng = new this.state.maps.LatLng(
            parseFloat(this.state.lat),
            parseFloat(this.state.lng)
            // parseFloat(this.state.userLat),
            // parseFloat(this.state.userLng)
        )
        //Recenter map to user position
        this.state.map.panTo(newLatLng)
        
        // Show all markers if they've been hidden
        this.state.markers.map((marker) => {
           return marker.marker.setVisible(true)
        })

        //Reset state to show all Inbound listings
        this.setState({
            size: '*',
            terms: '*',
            price: '*',
            results: this.state.allResults
        })

        this.state.map.setZoom(16);

        // Show clusters
        this.mapRef.current.classList.remove('hide');

        // reset filters to default
        this.filter1.current.selectedIndex = 0;
        this.filter2.current.selectedIndex = 0;
        this.filter3.current.selectedIndex = 0;

        this.hideResetButton();
        this.getInBoundListings();
        
    }

    showResetButton = () => {
        this.setState({
            showResetButton:true
        })
    }
    hideResetButton = () => {
        this.setState({
            showResetButton:false
        })
    }

    getGeolocationPostion = () => {
        
        navigator.geolocation.watchPosition((position) => {
            this.setState({
              // lat: position.coords.latitude,
              // lng: position.coords.longitude,
              userLat: position.coords.latitude,
              userLng: position.coords.longitude,
              userConsent: true,
            }, () => {
                if(this.state.userPositionSet === false){
                    this.setState({
                        userPositionSet: true,
                        lat:this.state.userLat,
                        lng:this.state.userLng
                    })
                }
            });
          },
          (err) => console.log(err),
          { enableHighAccuracy: true, timeout: 10000, maximumAge: 10000 },
          );
            
       
      }

    getInBoundListings = (reset) => {
        
        let allInboundListings = [];
        let results =  this.state.results;
        
        this.state.results.forEach((listing) => {
            // if(listing.acf_listings.image1 === null){
            //     //console.log(listing.title + " has no image")
            // }
            // if(listing.acf_listings.latitude === null){
            //     //console.log(listing.title + " has no latitude")
            // }
            // if(listing.acf_listings.longitude === null){
            //     //console.log(listing.title + " has no longitude")
            // }

            // If has lat/lng (to avoid errors with listing that don't have values)
            if(listing.acf_retail_properties.retailLatitude && listing.acf_retail_properties.retailLongitude){
            
                // If distance from user to listing is less than listingsRadius e.g 100m the push listing to state
                if(
                    this.googleMapRef.getBounds().contains({lat: parseFloat(listing.acf_retail_properties.retailLatitude), lng:parseFloat(listing.acf_retail_properties.retailLongitude)})
                ){

                
                    allInboundListings.push(
                        {
                            title:listing.title,
                            lat: parseFloat(listing.acf_retail_properties.retailLatitude), 
                            lng: parseFloat(listing.acf_retail_properties.retailLongitude),
                            img: listing.acf_retail_properties.images[0].image.localFile.childImageSharp.gatsbyImageData,
                            // tagline: listing.acf_retail_properties.taglineSummary,
                            // address: listing.acf_retail_properties.address,
                            slug: listing.slug,
                            terms: listing.acf_retail_properties.terms,
                            size: listing.acf_retail_properties.size,
                            price: listing.acf_retail_properties.price,
                        }
                    )
                }
            }
        })    

        this.setState({
            allInboundListings: allInboundListings,
        }, () => {
            this.markers();
            
        })
        
    }

    markers = () => {
        let infowindows = [];
        let markers = this.state.markers;
        const isClient = typeof document !== 'undefined';
        

        this.state.allInboundListings.forEach((listing, i) => {
            new this.state.maps.LatLng(listing.lat, listing.lng);
            let marker;
            let infowindow;
          
            marker = new this.googleRef.Marker({
                position: listing,
                map:this.state.map,
                icon:MapMarkerProperty,
                category:[listing.price, listing.terms, listing.size],
                title: listing.title,
            });

            

             // Add Click event to toggle visibility of Infowindow
            marker.addListener("click", (e) => {
               
                // Create Infowindow
                let infoContent;

                if(isClient){
                    const content = document.querySelector(`.infowindow[data-listing='${listing.title}']`);
                    infoContent = content.cloneNode(true)
                }
                infowindow = new this.googleRef.InfoWindow({
                    content: infoContent           
                });

                this.setState({
                    listingsWithActiveInfowindow: listing.slug
                })

                if(isClient && this.state.listingsWithActiveInfowindow){
                    setTimeout(function(){
                        const target = document.querySelector(`.listing[data-listing="${this.state.listingsWithActiveInfowindow}"]`);
                        target.parentNode.parentNode.scrollTop = target.offsetTop;
                    }.bind(this),1000)
                }
            
              
                if(isClient){
                    const oldMapElements = document.querySelectorAll('.gm-style-iw-a');

                    oldMapElements.forEach((element) => {
                        element.parentNode.remove();
                    })
                }

                infowindow.close();
                infowindow.open(this.state.map, marker);
                marker.setIcon(MapMarkerPropertyHover);
                
                this.setState({
                    InfoWindowTitle:'test',
                    InfoWindowImg:'test',
                    InfoWindowTagline:'test',
                    InfoWindowOffer:'test',
                    InfoWindowAddress:'test',
                    InfoWindowSlug:'test',
                }, () => {
                    this.setState({
                        InfoWindowTitle:listing.title,
                        InfoWindowImg:listing.img,
                        // InfoWindowTagline:listing.tagline,
                        // InfoWindowOffer:listing.offer,
                        // InfoWindowAddress:listing.address,
                        InfoWindowSlug:listing.slug,
                    })
                })

            });

            this.state.maps.event.addListener(marker, "mouseover", (e) => {
                marker.setIcon(MapMarkerPropertyHover);
                Array.from(this.listings.current.querySelectorAll('.listing')).map((listing) => {
                    if(listing.querySelector('.heading').innerText === marker.title){
                        listing.querySelector('article').classList.add('active')
                    }
                })
            });

            this.state.maps.event.addListener(marker, "mouseout", (e) => {
                marker.setIcon(MapMarkerProperty);
                Array.from(this.listings.current.querySelectorAll('.listing')).forEach((listing) => {
                    listing.querySelector('article').classList.remove('active')
                })
            });
            
            markers.push({marker:marker, title: listing.title, profile:listing.profile }); 
        })

    }

    
    initMap = (map, maps) => {
        // use map and maps objects
        this.googleMapRef = map
        this.googleRef = maps
        this.setState({map: map})
        this.setState({maps: maps})
        

        
        this.setState({
            mapBounds:map.getBounds()
        })

        let items = [];
        this.props.allProperties.nodes.forEach((item) => {
            items.push(item);
        });
        
        this.setState({
            results: items
        })
        
        

        // Add Idle event Listener 
        // Will recalculate map boundaries 
        // and update visible markers and listings
        this.state.maps.event.addListener(map, 'bounds_changed', function(){
            this.setState({
                mapBounds:map.getBounds(),
            })
            this.getInBoundListings();
            
        }.bind(this));

        this.state.maps.event.addListener(map, 'center_changed', function(){
            this.setState({
                hasPanned:true,
            })            
        }.bind(this));

        this.state.maps.event.addListener(map, 'idle', function(){
            this.setState({
                mapLoaded:true
            })
        }.bind(this));
    };
      

    
    render() {

        const isClient = typeof window !== 'undefined';
        const terms = this.props.terms;
        const size = this.props.size;
        const price = this.props.price;
        let priceFormatted = [];
        let sizeFormatted = [];
        
        price.nodes.map((item) => {
            var order = parseInt(item.description);
            priceFormatted.push({order: order, name:item.name });
        })

        priceFormatted.sort(function(a, b) {
            return a.order - b.order;
        });
        
        size.nodes.map((item) => {
            var order = parseInt(item.description);
            sizeFormatted.push({order: order, name:item.name });
        })

        sizeFormatted.sort(function(a, b) {
            return a.order - b.order;
        });
        

        
  
        function mapOptions(maps) {
            return {
            zoomControlOptions: {
                position: maps.ControlPosition.LEFT_TOP,
                style: maps.ZoomControlStyle.SMALL
            },
            scrollwheel: false,
            zoomControl: true,
            mapTypeControl: false,
            // minZoom:16,
            navigationControl: false,
            fullscreenControl:false,
            draggable: true,
            styles:mapStyles,
            };
        }

        
        
        
        return (

            
            <IndependentsMapAndFilter className="independents-map" >
                <MapWrap className={this.state.mapLoaded === true ? 'map properties-map loaded' : 'map properties-map'} ref={this.mapRef}>
                    <div className="map-loading">
                        <span>Map loading</span>
                        {/* <User className="beacon"/> */}
                    </div>
                    <div className="map-wrap-inner">
                        { isClient && (
                            <GoogleMapReact
                                bootstrapURLKeys={{
                                    key: process.env.GATSBY_GOOGLEMAP_API,
                                    libraries: ['geometry'],
                                }}
                                center={[this.state.lat, this.state.lng]}
                                defaultZoom={this.state.zoom}
                                options={mapOptions}
                                yesIWantToUseGoogleMapApiInternals
                                onGoogleApiLoaded={({ map, maps }) => this.initMap(map, maps)}
                            >
                        
                                {
                                // If user has Geolocation and accepts show them on the map
                                this.state.userLat && this.state.userLng && this.state.userConsent === true?
                                    <User
                                    lat={this.state.userLat}
                                    lng={this.state.userLng}
                                    />
                                : ''}
                        
                            </GoogleMapReact>
                        )}
                    </div>
                </MapWrap>
                <ToggleWrap className="toggleWrap"> 
                    <div 
                    className={this.state.filterActive === true ? 'active button' : 'button'} 
                    onClick={this.toggleFilters}>
                        <GrSort /> 
                        <span>{this.state.filterActive === true ? 'Hide' : 'Show'} </span> Filters
                    </div>
                    <div 
                    className={this.state.listingsActive === true ? 'active button' : 'button'} 
                    onClick={this.toggleListings}>
                        <IoIosContacts /> 
                        <span>{this.state.listingsActive === true ? 'Hide' : 'Show'} </span> Properties
                    </div>
                </ToggleWrap>
                <FilterWrap ref={this.filterWrap} className={this.state.filterActive === true ? 'showFilters' : '' }>
                    <div className="filters--wrapper">
                        <div className="heading">Filter properties</div>
                        
                        <button 
                            className={this.state.showResetButton === true ? "clear" : 'clear hidden'}
                            onClick={this.resetFilters}>Reset <IoIosCloseCircleOutline/>
                        </button>

                        <div className="filters-inner-wrapper">
                            <div className="select-wrap filter-terms">
                                <select
                                    ref={this.filter1}
                                    defaultValue="Terms"
                                    name="filter-terms"
                                    id="filter-terms"
                                    onChange={this.handleSelectChange}
                                    // onChange={this.handleTermsChange}
                                >
                                    <option value="Terms" disabled>Terms</option>
                                    <option
                                        value={'For Sale'}>For Sale
                                    </option>
                                    <option
                                        value={'To Let'}>To Let
                                    </option>
                                    {/* <option selected disabled>What?</option> */}
                                    {/* {
                                    terms.nodes.map((item, index) => {
                                        return(
                                            <option
                                                key={item.name+'-'+index}
                                                value={item.name}>{item.name}
                                            </option>
                                        )
                                    })
                                    } */}
                                </select>
                                <DD className="dd"/>
                            </div>
                            <div className="select-wrap filter-size">
                                <select
                                    ref={this.filter2}
                                    defaultValue="Size"
                                    name="filter-size"
                                    id="filter-size"
                                    onChange={this.handleSelectChange}
                                    // onChange={this.handleSizeChange}
                                >
                                    <option value="Size" disabled>Size</option>
                                    <option
                                        value={'Under 100sqm'}
                                        minValue={0}
                                        maxValue={99}
                                        >Under 100sqm
                                    </option>
                                    <option
                                        value={'100 - 500 sqm'}
                                        minValue={100}
                                        maxValue={500}
                                        >100 - 500 sqm
                                    </option>
                                    <option
                                        value={'500 - 1000 sqm'}
                                        minValue={501}
                                        maxValue={1000}
                                        >500 - 1000 sqm
                                    </option>
                                    {/* <option selected disabled>What?</option> */}
                                    {/* {
                                    sizeFormatted.map((item, index) => {
                                        return(
                                            <option
                                                key={item.name+'-'+index}
                                                value={item.name}>{item.name}
                                            </option>
                                        )
                                    })
                                    } */}
                                </select>
                                <DD className="dd"/>
                            </div>
                            <div className="select-wrap filter-price">

                                <select
                                    ref={this.filter3}
                                    defaultValue="Price"
                                    name="filter-price"
                                    id="filter-price"
                                    onChange={this.handleSelectChange}
                                    // onChange={this.handlePriceChange}
                                >
                                    <option value="Price" disabled>Price</option>
                                    <option
                                        value={'Under £20k'}
                                        minValue={0}
                                        maxValue={20000}
                                        >Under £20k
                                    </option>
                                    <option
                                        value={'Under £40k'}
                                        minValue={20001}
                                        maxValue={40000}
                                        >£20 - £40k
                                    </option>
                                    <option
                                        value={'Under £60k'}
                                        minValue={40001}
                                        maxValue={60000}
                                        >£40 - £60k
                                    </option>
                                    <option
                                        value={'Under £80k'}
                                        minValue={60001}
                                        maxValue={80000}
                                        >£60 - £80k
                                    </option>
                                </select>
                                <DD className="dd"/>
                            </div>
                            
                        </div>
                    </div>
                </FilterWrap>          
                <ListingsWrap id="listings-scroller" className={this.state.listingsActive === true ? 'showListings listings--wrapper' : 'listings--wrapper' } >
                    
                        <ListingsContainer
                        ref={this.listings}>
                        
                            {this.state.allInboundListings !== null ?
                                this.state.allInboundListings.length > 0 ?
                                        this.state.allInboundListings.map((listing, index) => {
                                            return(
                        
                                                <ListingWrapper
                                                key={listing.title+index}
                                                onMouseEnter={this.handleListingHoverHighlight}
                                                onMouseLeave={this.handleListingHoverHighlightClear}
                                                className="listing"
                                                data-listing={listing.slug}
                                                >
                        
                                                    <Listing
                                                        key={listing.title+index}
                                                        listing={listing}
                                                        customClass={this.state.listingsWithActiveInfowindow === listing.slug ? `is_active` : ``}
                                                    />
                        
                                                    <InfoWindow className="infowindow" data-listing={listing.title}>
                                                        <div className="image">
                                                            <GatsbyImage image={listing.img}/>
                                                        </div>
                                                        <div className="info">
                                                            <h2 className="heading">{listing.title}</h2>
                                                            {listing.terms ? <div className="tagline">{listing.terms}</div> : ''}
                                                            {listing.size ? <div className="tagline">{listing.size}sqm</div> : ''}
                                                            <div className="price">£{listing.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} p/a</div>
                                                        </div>
                                                        <div className="btn-wrap">
                                                            <Button
                                                                className="viewButton"
                                                                color="black"
                                                                bg="black"
                                                                text={'View property'}
                                                                link={'/properties/'+listing.slug}
                                                            />
                                                        </div>
                                                    </InfoWindow>
                                                </ListingWrapper>
                                               
                                            )
                        
                                        })
                        
                                :
                                <ErrorWarning  className="errorwarning">Sorry there are no properties that match your criteria in this area. Try zooming out to find other nearby independents.</ErrorWarning>
                            : ""
                            }
                         
                        </ListingsContainer>
                    </ListingsWrap>
            </IndependentsMapAndFilter>
        )
    }
}
